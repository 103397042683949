import axios from 'axios';
import { appConfig } from '../configs/app';

export type User = {
  id: string;
  name?: string;
  email: string;
  phone: string;
  companyId: string;
  isActive: boolean;
  roleIds?: [string];
  verifyCode?: string;
  codeExpirientTimestamp?: number;
  tos: Date;
  rid?: string;
  newNewsCount: number;
  newTasksCount: number;
  threadId?: string;
  isThreadInstructionsSent?: boolean;
  isUnlisted?: boolean;
};

class UserService {
  static async info(id = '') {
    try {
      const response = await axios.get(`${appConfig.apiUrl}/me`, {
        params: { id },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      return response.data;
    } catch (error) {
      return null;
    }
  }

  static async update(info: any) {
    const response = await axios.put(`${appConfig.apiUrl}/user`, info, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    const data = response.data;
    return data;
  }

  static async acceptTos() {
    const response = await axios.post(
      `${appConfig.apiUrl}/tos`,
      {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
  }

  static async updateRid(rid: string) {
    await axios.post(
      `${appConfig.apiUrl}/notifications?rid=${rid}`,
      {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
  }

  static async onFinishOnboarding(
    answers: any,
    name: string,
	email: string,
    roleIds: string[],
  ) {
    await axios.post(
      `${appConfig.apiUrl}/onboarding-answer`,
      {
        answers,
        name,
		email,
        roleIds,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
  }
}

export default UserService;
